import {
  buildClient,
  CommitmentPolicy,
  getClient,
  KMS,
  KmsKeyringBrowser,
} from '@aws-crypto/client-browser';
import { base64ToBytes, bytesToBase64 } from './textUtils';
import { Auth } from 'aws-amplify';

const utf8Encode = new TextEncoder();
const utf8Decode = new TextDecoder();

export default class EncryptionClient {
  static encrypt;
  static decrypt;
  static keyring;

  static async init() {
    if (this.keyring !== undefined) {
      return;
    }

    const credentials = await Auth.currentCredentials();
    const clientProvider = getClient(KMS, {
      region: 'eu-central-1', // Update with your region
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });
    const generatorKeyId = process.env.REACT_APP_GENERATOR_KEY_ID;
    this.keyring = new KmsKeyringBrowser({ clientProvider, generatorKeyId });
    const client = buildClient(
      CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT,
    );
    this.encrypt = client.encrypt;
    this.decrypt = client.decrypt;
  }

  static async encryptContent(content) {
    const json = JSON.stringify(content);
    const bytes = utf8Encode.encode(json);
    const encrypted = await this.encrypt(this.keyring, bytes);
    return bytesToBase64(encrypted.result);
  }

  static async decryptContent(encryptedBytes) {
    const bytes = base64ToBytes(encryptedBytes);
    const decrypted = await this.decrypt(this.keyring, bytes);
    const decoded = utf8Decode.decode(decrypted.plaintext);
    return JSON.parse(decoded);
  }

  static async encryptText(text) {
    const bytes = utf8Encode.encode(text);
    const encrypted = await this.encrypt(this.keyring, bytes);
    return bytesToBase64(encrypted.result);
  }

  static async decryptText(encryptedText) {
    const bytes = base64ToBytes(encryptedText);
    const decrypted = await this.decrypt(this.keyring, bytes);
    return utf8Decode.decode(decrypted.plaintext);
  }

  static async encryptBytes(bytes) {
    const encrypted = await this.encrypt(this.keyring, bytes);
    return encrypted.result;
  }

  static async decryptBytes(encryptedBytes) {
    const decrypted = await this.decrypt(this.keyring, encryptedBytes);
    return decrypted.plaintext;
  }
}
