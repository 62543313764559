import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  CssBaseline,
  GlobalStyles,
  Snackbar,
  SnackbarCloseReason,
  ThemeProvider,
} from '@mui/material';
import globalStyles from './globalStyles';
import { StyledEngineProvider } from '@mui/material/styles';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import { Provider } from 'react-redux';
import configureStore from './store';
import AppRoutes from './routes/Routes';
import Button from '@mui/material/Button';
import { I18nextProvider, useTranslation } from 'react-i18next';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
  newNotificationService,
  NotificationContext,
} from './contexts/NotificationContext';
import { useWhitelabel } from './hooks/useWhitelabel';
import Loader from './components/Loader';
import { WhitelabelContext } from './contexts/WhitelabelContext';
import i18n from './i18n';
import StorageApi from './api/StorageApi';
import EncryptionClient from './utils/EncryptionClient';

Amplify.configure(awsExports);
// @ts-ignore
const injectGlobalStyles = <GlobalStyles styles={globalStyles} />;

const App = () => {
  console.log('origin: %o', window.location.origin);

  const { t } = useTranslation();
  const waitingWorkerRef = useRef<any>();
  const [showUpdateNotification, setShowUpdateNotification] = useState(false);
  const store = useMemo(() => configureStore({}), []);
  const notificationService = useMemo(() => newNotificationService(), []);
  const { loaded, whitelabel, theme } = useWhitelabel();

  useEffect(() => {
    if (!loaded) {
      return;
    }

    // Initialize e2e encryption client
    void EncryptionClient.init();

    document.title = whitelabel?.name ?? 'SafeTalk';

    // Update favIcon
    let link = document.querySelector(
      "link[rel~='icon']",
    ) as HTMLLinkElement | null;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = whitelabel?.favIcon
      ? StorageApi.getPublicUrl(whitelabel.favIcon)!
      : '/favicon.svg';
  }, [loaded]);

  const onServiceWorkerUpdate = (
    registration: any,
    firstRenderUpdate: boolean,
  ) => {
    if (registration) {
      waitingWorkerRef.current = registration.waiting;
      // Reload page immediately if the update was found on first page render
      if (firstRenderUpdate) {
        updateServiceWorker();
      } else {
        setShowUpdateNotification(true);
      }
    }
  };

  useLayoutEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
  }, [onServiceWorkerUpdate]);

  const updateServiceWorker = () => {
    waitingWorkerRef.current.postMessage({ type: 'SKIP_WAITING' });
    setShowUpdateNotification(false);
    window.location.reload();
  };

  const onUpdateNotificationClose = (
    event: React.SyntheticEvent | Event,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowUpdateNotification(false);
  };

  return (
    <StyledEngineProvider injectFirst>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {injectGlobalStyles}
          {!loaded ? (
            <Loader />
          ) : (
            <WhitelabelContext.Provider value={whitelabel}>
              <Provider store={store}>
                <Snackbar
                  open={showUpdateNotification}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  onClose={onUpdateNotificationClose}
                >
                  <Alert
                    onClose={() => setShowUpdateNotification(false)}
                    elevation={6}
                    variant="filled"
                    severity="success"
                    action={
                      <Button
                        size="small"
                        style={{ color: '#f50057' }}
                        onClick={updateServiceWorker}
                      >
                        {t('refresh')}
                      </Button>
                    }
                  >
                    {t('updateFound')}
                  </Alert>
                </Snackbar>
                <NotificationContext.Provider value={notificationService}>
                  <AppRoutes />
                </NotificationContext.Provider>
              </Provider>
            </WhitelabelContext.Provider>
          )}
        </ThemeProvider>
      </I18nextProvider>
    </StyledEngineProvider>
  );
};

export default App;
